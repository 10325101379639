import React, { Component } from 'react';
import firebase from '../../firebase';
import moment from 'moment';
import numeral from 'numeral';


import DataTable from 'react-data-table-component';

export class CompetitorAdwords extends Component {
  constructor() {
    super();
    this.state = {
      keywords: [],
      toggledClearRows: false,
      filteredKeywords: [],
      selectedRows:[],
      keywordFilter: '',
      adwords_traffic : '',
      adwords_cost: '',  
      columns: [
        {
          name: 'Keyword',
          selector: 'keyword',
          sortable: true,
          grow: 1,
          maxWidth: "350px",
          cell: row => <div>{row.keyword}</div>

        },
        {
          name: 'Volume',
          selector: 'volume',
          sortable: true,
          right: true,
          width: "80px"
        },   
        {
          name: 'Position',
          selector: 'position',
          sortable: true,
          right: true,
          width: "80px"
        },   
        {
          name: 'CPC',
          selector: 'cpc',
          sortable: true,
          right: true,
          width: "80px"
        },
        {
          name: 'Traffic',
          selector: 'traffic',
          sortable: true,
          right: true,
          width: "60px"
        },
        {
          name: 'Traffic Cost',
          selector: 'traffic_cost',
          sortable: true,
          right: true,
          width: "100px"
        },
        {
          name: 'Traffic %',
          selector: 'traffic_percent',
          sortable: true,
          right: true,
          width: "80px"
        },        
        {
          name: 'Landing Page',
          selector: 'url',
          sortable: true,
          cell: row => <div><a href={row.url} target="_blank" rel="noopener noreferrer" style={{fontSize: "12px", textDecoration: "none"}}>{row.visible_url}</a></div>
        },
        {
          name: 'Last Seen',
          selector: 'last_seen',
          sortable: true,
          cell: row => <div>{row.last_seen_formatted}</div>

        },        
        {
          name: 'Ad Copy',
          selector: 'title',
          sortable: true,
          wrap: true,
          minWidth: "500px",
          cell: row => <div><h6>{row.title}</h6><p style={{fontSize: "12px"}}>{row.description}</p></div>
        },

      ],
    }

    this.handleKeywordFilter = this.handleKeywordFilter.bind(this);
    this.handleClearRows = this.handleClearRows.bind(this);

  }

  componentDidMount() {

    const compRef = firebase.database().ref(`competitors/${this.props.competitor_id}`);

    compRef.on('value', (snapshot) => {
      let competitor = snapshot.val();
      let keywords = [];

      if(typeof competitor.adwords !== undefined && Array.isArray(competitor.adwords) && competitor.adwords.length) {
        competitor.adwords.forEach( function(adword, index) {
          
          keywords.push({
            id: adword+index,
            keyword: adword.keyword,
            volume: parseInt(adword.search_volume),
            position: parseInt(adword.position),
            traffic: parseInt(adword.traffic),
            traffic_percent: parseFloat(adword.traffic_percent),
            url: adword.url,
            visible_url: adword.visible_url,
            cpc: parseFloat(adword.cpc),
            traffic_cost: parseFloat(adword.traffic_cost),
            title: adword.title,
            description: adword.description,
            last_seen: adword.last_seen,
            last_seen_formatted: moment.unix(adword.last_seen).format("MM-YY")
          });
          
        })
      }


      let adwordsCost = '';   
      if(typeof competitor.adwords_cost !== undefined) { 
        adwordsCost = competitor.adwords_cost; 
      }

      let adwordsTraffic = '';   
      if(typeof competitor.adwords_traffic !== undefined) { 
        adwordsTraffic = competitor.adwords_traffic; 
      }


      this.setState({
        keywords,
        filteredKeywords: keywords,
        keywordFilter: '',
        selectedRows: [],
        adwords_traffic : adwordsTraffic,
        adwords_cost: numeral(adwordsCost).format('$0,0') ,  
      });
    }) //end on value
  } //end componentDidMount 

  handleKeywordFilter(e) {   
    let filterTerm = e.target.value;
    let filteredKeywords = this.state.keywords.filter((term) => {
      return ( term.keyword.includes(filterTerm));
    })

    this.setState({
      keywordFilter: filterTerm,
      filteredKeywords
    })
  }

  handleClearRows = () => {
    this.setState({ toggledClearRows: !this.state.toggledClearRows})
  }


  render() {
    return (
      <div className="container-fluid px-0" style={{backgroundColor:"#f6f6f6"}}>
          <div className="row">
            <div className="col p-5">
              <h4 className="px-3 pt-0 pb-2">Estimated Monthly Adwords Traffic : <strong>{this.state.adwords_traffic}</strong></h4>
              <h4 className="p-3 pb-0 pt-1">Estimated Monthly Adwords Spend : <strong>{this.state.adwords_cost}</strong></h4>
            </div>
          </div>


          <div className="row">
            <div className="col pt-5 pb-0 pr-5 text-right">
              <input className="p-1" type="text" placeholder="search keywords" onChange={this.handleKeywordFilter} value={this.state.keywordFilter}/>
            </div>
          </div>

          <div className="row">
            <div className="col mx-auto p-5 page-text">
              <DataTable
                title="PPC Adwords"
                columns = {this.state.columns}
                data = {this.state.filteredKeywords}
                defaultSortField = "traffic"
                defaultSortAsc = {true}
                clearSelectedRows={this.state.toggledClearRows}
                pagination={true}
                paginationRowsPerPageOptions={[5, 20, 50]}
                paginationPerPage={5}
              />
            </div>
          </div>           
      </div>
      
    )
  }
}

export default CompetitorAdwords;