import React, { Component } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';

export default class Trends extends Component {

  constructor(props) {
    super(props);

    this.chartData = [
      {text: 'Sep 18', value: this.props.trend[0]},
      {text: 'Oct 18', value: this.props.trend[1]},
      {text: 'Nov 18', value: this.props.trend[2]},
      {text: 'Dec 18', value: this.props.trend[3]},
      {text: 'Jan 19', value: this.props.trend[4]},
      {text: 'Feb 19', value: this.props.trend[5]},
      {text: 'Mar 19', value: this.props.trend[6]},
      {text: 'Apr 19', value: this.props.trend[7]},
      {text: 'May 19', value: this.props.trend[8]},
      {text: 'Jun 19', value: this.props.trend[9]},
      {text: 'Jul 19', value: this.props.trend[10]},
      {text: 'Aug 19', value: this.props.trend[11]}, 
    ];

    this.state = {
      margin : {top: 20, right: 20, bottom: 30, left: 40},
      width : 1000
    }
    
  }

  render() {
    return (
        <div>
          <p className="text-center">12 Month Trend</p>
          <LineChart 
            width={500}
            height={250}
            data={this.chartData}
            margin={{ top: 5, right: 5, bottom: 5, left: 0 }}
            >
              <Line type="monotone" dataKey="value" stroke="#8884d8" />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
              <XAxis dataKey="text" />
              <YAxis />
            </LineChart>
            
        </div>
    );
  }

}