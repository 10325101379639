import React, { Component } from 'react';
import firebase from '../firebase';

import DataTable from 'react-data-table-component';

export class Keywords extends Component {

  constructor() {
    super();
    this.state = {
      keywords: [],
      toggledClearRows: false,
      filteredKeywords: [],
      keywordFilter: '',
      selectedRows: [],
      columns: [
        {
          name: 'Keyword',
          selector: 'keyword',
          sortable: true,
          grow: 5
        },
        {
          name: 'Volume',
          selector: 'volume',
          sortable: true,
          right: true,
        },   
        {
          name: 'Related',
          selector: 'related',
          sortable: true,
          right: true,
          hide: 'lg',
        },   
        {
          name: 'SEO',
          sortable: 'true',
          selector: 'seo_target',
          cell: function(row) {
            let icon = row.seo_target ? "SEO" : '';
            return icon; 
          },
        },
        {
          name: 'PPC',
          sortable: 'true',
          selector: 'ppc_target',
          cell: function(row) {
            let icon = row.ppc_target ? "PPC" : '';
            return icon; 
          }
        },
        {
          name: 'NEG',
          sortable: 'true',
          selector: 'negative_term',
          cell: function(row) {
            let icon = row.negative_term ? "NEG" : '';
            return icon; 
          },
        }        ],
    }

    this.handleKeywordFilter = this.handleKeywordFilter.bind(this);
    this.handleClearRows = this.handleClearRows.bind(this);
    this.makeSeoTarget = this.makeSeoTarget.bind(this);
    this.removeSeoTarget = this.removeSeoTarget.bind(this);
    this.makePpcTarget = this.makePpcTarget.bind(this);
    this.removePpcTarget = this.removePpcTarget.bind(this);
    this.makeNegTerm = this.makeNegTerm.bind(this);
    this.removeNegTerm = this.removeNegTerm.bind(this);
    this.renderKeywordActions = this.renderKeywordActions.bind(this);

  }


  componentDidMount() {
    const keywordsRef = firebase.database().ref('keywords');
    keywordsRef.on('value', (snapshot) => {
      let fbkeywords = snapshot.val();

      let newState = [];
      for (let fbkeyword in fbkeywords) {
        let seo_target = (typeof(fbkeywords[fbkeyword].seo_target) !== 'undefined' && fbkeywords[fbkeyword].seo_target )? true : false;
        let ppc_target = (typeof(fbkeywords[fbkeyword].ppc_target) !== 'undefined' && fbkeywords[fbkeyword].ppc_target )? true : false;
        let negative_term = (typeof(fbkeywords[fbkeyword].negative_term) !== 'undefined' && fbkeywords[fbkeyword].negative_term )? true : false;

        newState.push({
          id: fbkeyword,
          keyword: fbkeywords[fbkeyword].keyword,
          volume: parseInt(fbkeywords[fbkeyword].volume),
          related: parseFloat(fbkeywords[fbkeyword].related),
          seo_target: seo_target,
          ppc_target: ppc_target,
          negative_term: negative_term
        });
      }

      this.setState({
        keywords: newState,
        filteredKeywords: newState,
        keywordFilter: '',
        selectedRows: []
      });
    });
  }

  handleKeywordFilter(e) {   
    let filterTerm = e.target.value;
    let filteredKeywords = this.state.keywords.filter((term) => {
      return ( term.keyword.includes(filterTerm));
    })

    this.setState({
      keywordFilter: filterTerm,
      filteredKeywords
    })
  }

  handleRowSelect = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    //console.log('Selected Rows: ', state.selectedRows);
    this.setState({ toggledClearRows: false, selectedRows: state.selectedRows})
  };

  handleClearRows = () => {
    this.setState({toggledClearRows: !this.state.toggledClearRows })
  }

  makeSeoTarget = () => {
    this.state.selectedRows.forEach(function(row) {
      const keywordsRef = firebase.database().ref(`keywords/${row.id}`);
      keywordsRef.update({
        seo_target: true,
      });
    });
    //this.handleClearRows();  
  }

  removeSeoTarget = () => {
    this.state.selectedRows.forEach(function(row) {
      const keywordsRef = firebase.database().ref(`keywords/${row.id}`);
      keywordsRef.update({
        seo_target: false,
      });
    });
    //this.handleClearRows();  
  }

  makePpcTarget = () => {
    this.state.selectedRows.forEach(function(row) {
      const keywordsRef = firebase.database().ref(`keywords/${row.id}`);
      keywordsRef.update({ ppc_target: true });
    });
    //this.handleClearRows();  
  }

  removePpcTarget = () => {
    this.state.selectedRows.forEach(function(row) {
      const keywordsRef = firebase.database().ref(`keywords/${row.id}`);
      keywordsRef.update({ ppc_target: false });
    });
    //this.handleClearRows();  
  }

  makeNegTerm = () => {
    this.state.selectedRows.forEach(function(row) {
      const keywordsRef = firebase.database().ref(`keywords/${row.id}`);
      keywordsRef.update({ negative_term: true });
    });
    //this.handleClearRows();  
  }

  removeNegTerm = () => {
    this.state.selectedRows.forEach(function(row) {
      const keywordsRef = firebase.database().ref(`keywords/${row.id}`);
      keywordsRef.update({ negative_term: false });
    });
    //this.handleClearRows();  
  }

  renderKeywordActions = () => {
    if(this.state.selectedRows.length > 0) {
      return (
        <div className="col ">
          <h5>Actions</h5>
          <hr/>
          <section>
            <h4>SEO</h4>
            <button onClick={this.makeSeoTarget}>Make SEO Target</button>
            <button onClick={this.removeSeoTarget}>Remove SEO Target</button>
          </section>
          <section>
            <h4>PPC</h4>
            <button onClick={this.makePpcTarget}>Make PPC Target</button>
            <button onClick={this.removePpcTarget}>Remove PPC Target</button>
          </section>   
          <section>
            <h4>Negative Term</h4>
            <button onClick={this.makeNegTerm}>Make Negative Term</button>
            <button onClick={this.removeNegTerm}>Remove Negative Term</button>
          </section>               
        </div>
      )
    }
  }

  render() {
      return (
        <div className="container-fluid px-0">

            <div className="row">
              <div className="col">
                <input type="text" placeholder="search keywords" onChange={this.handleKeywordFilter} value={this.state.keywordFilter}/>
                <button onClick={this.handleClearRows}>Clear Rows</button>
              </div>
            </div>

            <div className="row">
              <div className="col mx-auto p-5 page-text">

                <DataTable
                  title="Keywords"
                  columns = {this.state.columns}
                  data = {this.state.filteredKeywords}
                  defaultSortField = "volume"
                  defaultSortAsc = {false}
                  selectableRows // add for checkbox selection
                  onRowSelected={this.handleRowSelect}
                  clearSelectedRows={this.state.toggledClearRows}
                  pagination={true}
                  paginationRowsPerPageOptions={[20, 50, 100]}
                  dense={true}
                />

              </div>

              {this.renderKeywordActions()}
              
            </div>

        </div>
        
      )
  }
}


export default Keywords
