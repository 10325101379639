import React, { Component } from 'react';
import firebase from '../../firebase';

import DataTable from 'react-data-table-component';

export class CompetitorOverview extends Component {

  constructor() {
    super();
    this.state = {
      competitors: [],
      columns: [
        {
          name: 'Name',
          selector: 'competitor_name',
          sortable: true,
          grow: 3
        },
        {
          name: 'Domain',
          selector: 'domain',
        },

        {
          name: 'Organic Traffic',
          selector: 'organic_traffic',
          sortable: true,
          right: true,
        },   
        {
          name: 'Organic Keywords',
          selector: 'organic_keywods',
          sortable: true,
          right: true,
        },  
        {
          name: 'Organic Value',
          selector: 'organic_cost',
          sortable: true,
          right: true,
        },           
        {
          name: 'Adwords Traffic',
          selector: 'adwords_traffic',
          sortable: true,
          right: true,
        },           
        {
          name: 'Adwords Cost',
          selector: 'adwords_cost',
          sortable: true,
          right: true,
        },           

      ],
    }


  }


  componentDidMount() {
    const compRef = firebase.database().ref('competitors');
    compRef.on('value', (snapshot) => {

      let fbCompetitors = snapshot.val();
      let competitors = [];

      fbCompetitors.forEach( function(comp, index) {      
        competitors.push({
          id: index,
          competitor_name: comp.competitor_name,
          domain: comp.domain,
          organic_traffic: parseFloat(comp.organic_traffic),
          organic_keywods: parseInt(comp.organic_keywords),
          organic_cost: parseInt(comp.organic_cost),
          adwords_traffic: parseInt(comp.adwords_traffic),
          adwords_cost: parseInt(comp.adwords_cost),
        });
      })

      this.setState({ competitors});

    });
  }

  render() {
      return (
        <div className="container-fluid px-0">
            <div className="row">
              <div className="col mx-auto p-5 pt-0 page-text">
                <DataTable
                  title="Competitors"
                  columns = {this.state.columns}
                  data = {this.state.competitors}
                  defaultSortField = "organic_traffic"
                  defaultSortAsc = {false}
                  keyField = 'domain'
                  highlightOnHover
                />

              </div>              
            </div>

        </div>
        
      )
  }
}


export default CompetitorOverview
