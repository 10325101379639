import React, { Component } from 'react';
import firebase from '../../firebase';

import DataTable from 'react-data-table-component';
import TableRowExpanded from './TableRowExpanded';

export class RelatedKeywords extends Component {

  constructor() {
    super();
    this.state = {
      keywords: [],
      filteredKeywords: [],
      keywordFilter: '',
      columns: [
        {
          name: 'Keyword',
          selector: 'keyword',
          sortable: true,
          grow: 3
        },
        {
          name: 'Volume',
          selector: 'volume',
          sortable: true,
          right: true,
        },   
        {
          name: 'CPC',
          selector: 'cpc',
          sortable: true,
          right: true,
        },  
        {
          name: 'Difficulty',
          selector: 'keyword_difficulty',
          sortable: true,
          right: true,
        },           
        {
          name: 'Competition',
          selector: 'competitive_density',
          sortable: true,
          right: true,
        },           

        {
          name: 'SEO',
          sortable: 'true',
          selector: 'seo_target',
          width: '80px',
          cell: function(row) {
            let icon = row.seo_target ? 'fa fa-star text-warning' : '';
            return <div><i className={icon}></i></div>; 
          },
        },
        {
          name: 'PPC',
          sortable: 'true',
          selector: 'ppc_target',
          width: '80px',
          cell: function(row) {
            let icon = row.ppc_target ? 'fa fa-dollar-sign text-success' : '';
            return <div><i className={icon}></i></div>; 
          },
        },
        {
          name: 'NEG',
          sortable: 'true',
          selector: 'negative_term',
          width: '80px',
          cell: function(row) {
            let icon = row.negative_term ? 'fa fa-ban text-danger' : '';
            return <div><i className={icon}></i></div>; 
          },
        }        ],
    }

    this.handleKeywordFilter = this.handleKeywordFilter.bind(this);
  }


  componentDidMount() {
    const keywordsRef = firebase.database().ref('keywords');
    keywordsRef.on('value', (snapshot) => {
      let fbkeywords = snapshot.val();

      let newState = [];
      for (let fbkeyword in fbkeywords) {
        let seo_target = (typeof(fbkeywords[fbkeyword].seo_target) !== 'undefined' && fbkeywords[fbkeyword].seo_target )? true : false;
        let ppc_target = (typeof(fbkeywords[fbkeyword].ppc_target) !== 'undefined' && fbkeywords[fbkeyword].ppc_target )? true : false;
        let negative_term = (typeof(fbkeywords[fbkeyword].negative_term) !== 'undefined' && fbkeywords[fbkeyword].negative_term )? true : false;
        let serp_results = (typeof(fbkeywords[fbkeyword].serp_results) !== 'undefined') ? fbkeywords[fbkeyword].serp_results : false;
        let trend = fbkeywords[fbkeyword].trend;
        let trendSplit = trend.split(","); 

        newState.push({
          id: fbkeyword,
          keyword: fbkeywords[fbkeyword].keyword,
          volume: parseInt(fbkeywords[fbkeyword].volume),
          related: parseFloat(fbkeywords[fbkeyword].related),
          seo_target: seo_target,
          ppc_target: ppc_target,
          negative_term: negative_term,
          serp_results : serp_results,
          cpc : parseFloat(fbkeywords[fbkeyword].cpc),
          keyword_difficulty : parseFloat(fbkeywords[fbkeyword].keyword_difficulty),
          serp_features : parseFloat(fbkeywords[fbkeyword].serp_features),
          trend : trendSplit,
          competitive_density : parseFloat(fbkeywords[fbkeyword].competitive_density)
        });
      }

      this.setState({
        keywords: newState,
        filteredKeywords: newState,
        keywordFilter: '',
        selectedRows: []
      });
    });
  }

  handleKeywordFilter(e) {   
    let filterTerm = e.target.value;
    let filteredKeywords = this.state.keywords.filter((term) => {
      return ( term.keyword.includes(filterTerm));
    })

    this.setState({
      keywordFilter: filterTerm,
      filteredKeywords
    })
  }


  clearSearch = () => {
    this.setState({
      keywordFilter : '',
      filteredKeywords : this.state.keywords
    })
  }


  render() {
      return (
        <div className="container-fluid px-0">

            <div className="row">
              <div className="col pt-5 pb-0 pr-5 text-right">
                <input className="p-2" type="text" placeholder="search keywords" onChange={this.handleKeywordFilter} value={this.state.keywordFilter}/>
                <button className="p-2" onClick={this.clearSearch} style={{fontSize: '24px', border: 'none'}}><i className="fa fa-times-circle"/></button>
              </div>
            </div>


            <div className="row">
              <div className="col mx-auto p-5 pt-0 page-text">

                <DataTable
                  title="Related Keywords"
                  columns = {this.state.columns}
                  data = {this.state.filteredKeywords}
                  defaultSortField = "volume"
                  defaultSortAsc = {false}
                  expandableRows
                  highlightOnHover
                  pagination={true}
                  paginationRowsPerPageOptions={[20, 50, 100]}
                  dense={true}
                  expandableRowsComponent={<TableRowExpanded />}
                />

              </div>
              
            </div>

        </div>
        
      )
  }
}


export default RelatedKeywords
