// src/firebase.js
import firebase from 'firebase'

const config = {
  apiKey: "AIzaSyCBOlToQ5bv79bvjpeun99pNooOvYC2d6c",
  authDomain: "bcsp-examcore.firebaseapp.com",
  databaseURL: "https://bcsp-examcore.firebaseio.com",
  projectId: "bcsp-examcore",
  storageBucket: "",
  messagingSenderId: "961287344751",
  appId: "1:961287344751:web:6fc56e70cf0aaa7f"
};

firebase.initializeApp(config);

export default firebase;
