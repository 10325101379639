import React, { Component } from 'react';
import firebase from '../firebase';

class Notes extends Component {
  constructor() {
    super();
    this.state = {
      currentNote: '',
      username: '',
      notes: []
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const notesRef = firebase.database().ref('notes');
    notesRef.on('value', (snapshot) => {
      let fbnotes = snapshot.val();
      let newState = [];
      for (let fbnote in fbnotes) {
        newState.push({
          id: fbnote,
          note: fbnotes[fbnote].note,
          user: fbnotes[fbnote].user
        });
      }
      this.setState({
        notes: newState
      });
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const notesRef = firebase.database().ref('notes');
    const item = {
      note: this.state.currentNote,
      user: this.state.username
    }
    notesRef.push(item);
    this.setState({
      currentNote: '',
      username: ''
    });
  }

  removeItem(noteId) {
    const notesRef = firebase.database().ref(`/notes/${noteId}`);
    notesRef.remove();
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  render() {
    return (
      <div className="container-fluid px-0" style={{}} >


        <div className="container">
          <div className="row">
            <section className="add-note">
              <form onSubmit={this.handleSubmit}>
                <input type="text" name="username" placeholder="Enter name" onChange={this.handleChange} value={this.state.username} />
                <input type="text" name="currentNote" placeholder="Enter note" onChange={this.handleChange} value={this.state.currentNote} />
                <button>Add Note</button>
              </form>
            </section>
          </div>
          <div className="row">
            <section className='display-item'>
              <div className="wrapper">
                <ul>
                  {this.state.notes.map((note) => {
                    return (
                      <li key={note.id}>
                        <h3>{note.note}</h3>
                        <p>Entered by: {note.user}</p>
                        <button onClick={() => this.removeItem(note.id)}>Remove Item</button>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </section>
          </div>
        </div>

      </div>
      
    )
  }
}

export default Notes;